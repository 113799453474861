/* eslint-disable function-paren-newline */
import { lazy } from 'react';
import MainLayout from './components/layouts/MainLayout';
import Loadable from './components/ui/Loadable';
import { Navigate } from 'react-router-dom';
import AuthGuard from './components/Guards/AuthGuard/AuthGuard';
import PermissionGuard, { PermissionCodes, PermissionRights as Rights } from './components/Guards/PermissionGuard/PermissionGuard';

// - Authentication pages
const Login = Loadable(lazy(() => import('./pages/auth/Login')));

const RoleSearch = Loadable(lazy(() => import('./pages/administrator/role/RoleSearch')));
// const RoleEdit = Loadable(lazy(() => import('./pages/administrator/role/RoleEdit')));
const RoleSearchAddEdit = Loadable(lazy(() => import('./pages/administrator/role/components/RoleSearchAddEditForm/RoleSearchAddEditForm')));

// const Overview = Loadable(lazy(() => import('./pages/dashboard/Overview')));
const Overview = Loadable(lazy(() => import('./pages/Dashboard/Dashboard')));
// const Overview = Loadable(lazy(() => import('./pages/Dashboard/Overview')));
const ObjectConfigurationSearch = Loadable(lazy(() => import('./pages/ObjectConfigurationSearch/ObjectConfigurationSearch')));

const RequestSearch = Loadable(lazy(() => import('./pages/RequestSearch/RequestSearch')));
const RequestSearchAddEditForm = Loadable(lazy(() => import('./pages/RequestSearch/components/RequestSearchAddEditForm/RequestSearchAddEditForm')));
const ActionSearch = Loadable(lazy(() => import('./pages/ActionSearch/ActionSearch')));
// const RootCauseSearch = Loadable(lazy(() => import('./pages/RootCauseSearch/RootCauseSearch')));
// const RootCauseSearchAddEdit = Loadable(
//   lazy(() =>
//     import(
//       './pages/RootCauseSearch/components/RootCauseSearchAddEditForm/RootCauseSearchAddEditForm'
//     )
//   )
// );
const MappingSearch = Loadable(lazy(() => import('./pages/MappingSearch/MappingSearch')));
const MappingSearchAddEdit = Loadable(lazy(() => import('./pages/MappingSearch/components/MapSearchAddEditForm/MapSearchAddEditForm')));
const RegionManagement = Loadable(lazy(() => import('./pages/RegionManagement/RegionManagement')));
const RegionManagementAddEditForm = Loadable(lazy(() => import('./pages/RegionManagement/components/RegionManagementAddEditForm/RegionManagementAddEditForm')));
const RootCauseNameSearch = Loadable(lazy(() => import('./pages/RootCauseNameSearch/RootCauseNameSearch')));
const RootCauseNameSearchAddEdit = Loadable(
  lazy(() => import('./pages/RootCauseNameSearch/components/RootCauseNameSearchAddEditForm/RootCauseNameSearchAddEditForm'))
);
const ProbabilitySearch = Loadable(lazy(() => import('./pages/ProbabilitySearch/ProbabilitySearch')));
const ProbabilitySearchAddEdit = Loadable(lazy(() => import('./pages/ProbabilitySearch/components/ProbabilityAddEditForm/ProbabilityAddEditForm')));

const UserSearch = Loadable(lazy(() => import('./pages/administrator/user/UserSearch')));
const UserCreate = Loadable(lazy(() => import('./pages/administrator/user/UserCreate')));
// const UserAddEditForm = Loadable(lazy(() => import('./pages/administrator/user/components/UserAddEditForm/UserAddEditForm')));

const CompanySearch = Loadable(lazy(() => import('./pages/CompanySearch/CompanySearch')));
const CompanySearchAddEdit = Loadable(lazy(() => import('./pages/CompanySearch/components/CompanySearchAddEditForm/CompanySearchAddEditForm')));
const SeveritySearch = Loadable(lazy(() => import('./pages/SeverityManagement/SeveritySearch')));
const SeverityManagementAddEditForm = Loadable(lazy(() => import('./pages/SeverityManagement/components/SeveritySearchAddEditForm/SeveritySearchAddEditForm')));
const CountrySearch = Loadable(lazy(() => import('./pages/CountrySearch/CountrySearch')));
const CountrySearchAddEdit = Loadable(lazy(() => import('./pages/CountrySearch/components/CountrySearchAddEditForm/CountrySearchAddEditForm')));
const CreateRequest = Loadable(lazy(() => import('./pages/CreateRequest/CreateRequest')));
const IssueTypeSearch = Loadable(lazy(() => import('./pages/IssueTypeSearch/IssueTypeSearch')));
const IssueTypeSearchAddEdit = Loadable(lazy(() => import('./pages/IssueTypeSearch/components/IssueTypeSearchAddEditForm/IssueTypeSearchAddEditForm')));
const RequestCategorySource = Loadable(lazy(() => import('./pages/RequestCategorySourceFilter/RequestCategorySource')));
const RequestCategorySourceAddEditForm = Loadable(
  lazy(() => import('./pages/RequestCategorySourceFilter/components/RequestCategorySourceAddEditForm/RequestCategorySourceAddEditForm'))
);
const RootCauseCategorySearch = Loadable(lazy(() => import('./pages/RootCauseCategorySearch/RootCauseCategorySearch')));
const RootCauseCategorySearchAddEdit = Loadable(
  lazy(() => import('./pages/RootCauseCategorySearch/components/RootCauseCategorySearchAddEditForm/RootCauseCategorySearchAddEditForm'))
);
const EmailSearch = Loadable(lazy(() => import('./pages/EmailSearch/EmailSearch')));
const Verification = Loadable(lazy(() => import('./pages/Verification/VerificationSearch')));
const RequestCategorySearch = Loadable(lazy(() => import('./pages/RequestCategorySearch/RequestCategorySearch')));
const RequestCategoryAddEditForm = Loadable(
  lazy(() => import('./pages/RequestCategorySearch/components/RequestCategoryAddEditForm/RequestCategoryAddEditForm'))
);
const RiskRanking = Loadable(lazy(() => import('./pages/RiskRanking/RiskRanking')));
const RiskRankingAddEditForm = Loadable(lazy(() => import('./pages/RiskRanking/components/RiskRankingAddEditForm/RiskRankingAddEditForm')));
const LocationSearch = Loadable(lazy(() => import('./pages/LocationSearch/LocationSearch')));
const LocationSearchAddEditForm = Loadable(lazy(() => import('./pages/LocationSearch/components/LocationSearchAddEditForm/LocationSearchAddEditForm')));

const ClientPassword = Loadable(lazy(() => import('./pages/administrator/user/components/ClientPassword')));
const ForgotPassword = Loadable(lazy(() => import('./pages/auth/components/ForgotPassword/ForgotPassword')));
// Experiment Feautures
const ExperimentFeature = Loadable(
  lazy(() => import('./pages/ExperimentFeature/ExperimentFeature'))
);

// - My Actions
const MyActions = Loadable(
  lazy(() => import('./pages/MyActions/MyActions'))
);
const ObjectConfigAddEditForm = Loadable(
  lazy(() => import('./pages/ObjectConfigurationSearch/components/ObjectConfigurationAddEditForm'))
);
const ListSearch = Loadable(
  lazy(() => import('./pages/ListSearch/ListSearch'))
);
const ListDetail = Loadable(
  lazy(() => import('./pages/ListSearch/ListDetails'))
);

// - Error pages
const AuthorizationError = Loadable(lazy(() => import('./pages/error/AuthorizationError')));

const NotFound = Loadable(lazy(() => import('./pages/error/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/error/ServerError')));

const AccountSetting = Loadable(
  lazy(() => import('./pages/AccountSetting/AccountSetting'))
);

const routes = [
  {
    path: 'role',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.Role} right={Rights.Search}>
            <RoleSearch />
          </PermissionGuard>
        ),
      },
      {
        path: 'add',
        element: (
          <PermissionGuard permission={PermissionCodes.Role} right={Rights.Add}>
            <RoleSearchAddEdit />
          </PermissionGuard>
        ),
      },
      {
        path: ':id/edit',
        element: (
          <PermissionGuard permission={PermissionCodes.Role} right={Rights.Edit}>
            <RoleSearchAddEdit />
          </PermissionGuard>
        ),
      },
      {
        path: 'users',
        element: (
          <PermissionGuard permission={PermissionCodes.User} right={Rights.Search}>
            <UserSearch />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'admin/users',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.User} right={Rights.Search}>
            <UserSearch />
          </PermissionGuard>
        ),
      },
      {
        path: 'add',
        element: (
          <PermissionGuard permission={PermissionCodes.User} right={Rights.Add}>
            <UserCreate />
          </PermissionGuard>
        ),
      },
      {
        path: ':userId/edit',
        element: (
          <PermissionGuard permission={PermissionCodes.User} right={Rights.Edit}>
            <UserCreate />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Overview />,
      },
      // {
      //   path: 'um',
      //   element: <Overview />,
      // },
    ],
  },
  {
    path: 'requests',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.Request} right={Rights.Search}>
            <RequestSearch />
          </PermissionGuard>
        ),
      },
      {
        path: '?jobname={jobName}',
        element: (
          <PermissionGuard permission={PermissionCodes.Request} right={Rights.Search}>
            <RequestSearch />
          </PermissionGuard>
        ),
      },
      {
        path: '?subject={subjectName}',
        element: (
          <PermissionGuard permission={PermissionCodes.Request} right={Rights.Search}>
            <RequestSearch />
          </PermissionGuard>
        ),
      },
      {
        path: '?jobname={jobName}&subject={subjectName}',
        element: (
          <PermissionGuard permission={PermissionCodes.Request} right={Rights.Search}>
            <RequestSearch />
          </PermissionGuard>
        ),
      },
      // {
      //   path: 'add',
      //   element: (
      //     <PermissionGuard permission={PermissionCodes.Request} right={Rights.Add}>
      //       <RequestSearchAddEditForm />
      //     </PermissionGuard>
      //   ),
      // },
      {
        path: ':id/edit',
        element: (
          <PermissionGuard permission={PermissionCodes.Request} right={Rights.Edit}>
            <RequestSearchAddEditForm />
          </PermissionGuard>
        ),
      },
      {
        path: ':id/view',
        element: <RequestSearchAddEditForm viewMode />
      },
    ],
  },
  {
    path: 'objectConfiguration',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.ObjectConfiguration} right={Rights.Search}>
            <ObjectConfigurationSearch />
          </PermissionGuard>
        ),
      },
      {
        path: 'add',
        element: (
          <PermissionGuard permission={PermissionCodes.ObjectConfiguration} right={Rights.Add}>
            <ObjectConfigAddEditForm />
          </PermissionGuard>
        ),
      },
      {
        path: ':id/edit',
        element: (
          <PermissionGuard permission={PermissionCodes.ObjectConfiguration} right={Rights.Edit}>
            <ObjectConfigAddEditForm />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'actions',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.Action} right={Rights.Search}>
            <ActionSearch />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'createRequest',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.Request} right={Rights.Add}>
            <CreateRequest />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'locations',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.Location} right={Rights.Search}>
            <LocationSearch />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'location',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'add',
        element: (
          <PermissionGuard permission={PermissionCodes.Location} right={Rights.Add}>
            <LocationSearchAddEditForm />
          </PermissionGuard>
        ),
      },
      {
        path: ':id/edit',
        element: (
          <PermissionGuard permission={PermissionCodes.Location} right={Rights.Edit}>
            <LocationSearchAddEditForm />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'companies',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.Company} right={Rights.Search}>
            <CompanySearch />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'company',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'add',
        element: (
          <PermissionGuard permission={PermissionCodes.Company} right={Rights.Add}>
            <CompanySearchAddEdit />
          </PermissionGuard>
        ),
      },
      {
        path: ':id/edit',
        element: (
          <PermissionGuard permission={PermissionCodes.Company} right={Rights.Edit}>
            <CompanySearchAddEdit />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'probabilityRankings',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.Probability} right={Rights.Search}>
            <ProbabilitySearch />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'probabilityRanking',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'add',
        element: (
          <PermissionGuard permission={PermissionCodes.Probability} right={Rights.Add}>
            <ProbabilitySearchAddEdit />
          </PermissionGuard>
        ),
      },
      {
        path: ':id/edit',
        element: (
          <PermissionGuard permission={PermissionCodes.Probability} right={Rights.Edit}>
            <ProbabilitySearchAddEdit />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'rootCauses',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.RootCause} right={Rights.Search}>
            <RootCauseNameSearch />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'rootCause',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'add',
        element: (
          <PermissionGuard permission={PermissionCodes.RootCause} right={Rights.Add}>
            <RootCauseNameSearchAddEdit />
          </PermissionGuard>
        ),
      },
      {
        path: ':id/edit',
        element: (
          <PermissionGuard permission={PermissionCodes.RootCause} right={Rights.Edit}>
            <RootCauseNameSearchAddEdit />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'regions',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.Region} right={Rights.Search}>
            <RegionManagement />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'region',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'add',
        element: (
          <PermissionGuard permission={PermissionCodes.Region} right={Rights.Add}>
            <RegionManagementAddEditForm />
          </PermissionGuard>
        ),
      },
      {
        path: ':id/edit',
        element: (
          <PermissionGuard permission={PermissionCodes.Region} right={Rights.Edit}>
            <RegionManagementAddEditForm />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'issueTypes',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.IssueType} right={Rights.Search}>
            <IssueTypeSearch />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'issueType',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'add',
        element: (
          <PermissionGuard permission={PermissionCodes.IssueType} right={Rights.Add}>
            <IssueTypeSearchAddEdit />
          </PermissionGuard>
        ),
      },
      {
        path: ':id/edit',
        element: (
          <PermissionGuard permission={PermissionCodes.IssueType} right={Rights.Edit}>
            <IssueTypeSearchAddEdit />
          </PermissionGuard>
        ),
      }
    ],
  },
  {
    path: 'rootCauseCategories',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.RootCauseCategory} right={Rights.Search}>
            <RootCauseCategorySearch />
          </PermissionGuard>
        ),
      },
      {
        path: 'add',
        element: (
          <PermissionGuard permission={PermissionCodes.RootCauseCategory} right={Rights.Add}>
            <RootCauseCategorySearchAddEdit />
          </PermissionGuard>
        ),
      },
      {
        path: ':id/edit',
        element: (
          <PermissionGuard permission={PermissionCodes.RootCauseCategory} right={Rights.Edit}>
            <RootCauseCategorySearchAddEdit />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'countries',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.Country} right={Rights.Search}>
            <CountrySearch />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'country',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'add',
        element: (
          <PermissionGuard permission={PermissionCodes.Country} right={Rights.Add}>
            <CountrySearchAddEdit />
          </PermissionGuard>
        ),
      },
      {
        path: ':id/edit',
        element: (
          <PermissionGuard permission={PermissionCodes.Country} right={Rights.Edit}>
            <CountrySearchAddEdit />
          </PermissionGuard>
        ),
      }
    ],
  },
  {
    path: 'sentEmails',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.Email} right={Rights.Search}>
            <EmailSearch />
          </PermissionGuard>
        ),
      },
      {
        path: ':id',
        element: (
          <PermissionGuard permission={PermissionCodes.Email} right={Rights.Search}>
            <EmailSearch />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'verification',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.Verification} right={Rights.Search}>
            <Verification />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'requestCategorySourceMapping',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.RequestCategorySource} right={Rights.Search}>
            <RequestCategorySource />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'requestCategorySource',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'add',
        element: (
          <PermissionGuard permission={PermissionCodes.RequestCategorySource} right={Rights.Add}>
            <RequestCategorySourceAddEditForm />
          </PermissionGuard>
        ),
      },
      {
        path: ':id/edit',
        element: (
          <PermissionGuard permission={PermissionCodes.RequestCategorySource} right={Rights.Edit}>
            <RequestCategorySourceAddEditForm />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'riskRankings',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.Risk} right={Rights.Search}>
            <RiskRanking />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'riskRanking',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'add',
        element: (
          <PermissionGuard permission={PermissionCodes.Risk} right={Rights.Add}>
            <RiskRankingAddEditForm />
          </PermissionGuard>
        ),
      },
      {
        path: ':id/edit',
        element: (
          <PermissionGuard permission={PermissionCodes.Risk} right={Rights.Edit}>
            <RiskRankingAddEditForm />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'severities',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.Severity} right={Rights.Search}>
            <SeveritySearch />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'severity',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'add',
        element: (
          <PermissionGuard permission={PermissionCodes.Severity} right={Rights.Add}>
            <SeverityManagementAddEditForm />
          </PermissionGuard>
        ),
      },
      {
        path: ':id/edit',
        element: (
          <PermissionGuard permission={PermissionCodes.Severity} right={Rights.Edit}>
            <SeverityManagementAddEditForm />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'requestCategories',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.RequestCategory} right={Rights.Search}>
            <RequestCategorySearch />
          </PermissionGuard>
        ),
      },
      {
        path: 'add',
        element: (
          <PermissionGuard permission={PermissionCodes.RequestCategory} right={Rights.Add}>
            <RequestCategoryAddEditForm />
          </PermissionGuard>
        ),
      },
      {
        path: ':id/edit',
        element: (
          <PermissionGuard permission={PermissionCodes.RequestCategory} right={Rights.Edit}>
            <RequestCategoryAddEditForm />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'lists',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.List} right={Rights.Search}>
            <ListSearch />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'list',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: ':id/edit',
        element: (
          <PermissionGuard permission={PermissionCodes.List} right={Rights.Edit}>
            <ListDetail />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'mappings',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.Mapping} right={Rights.Search}>
            <MappingSearch />
          </PermissionGuard>
        ),
      },
      {
        path: 'add',
        element: (
          <PermissionGuard permission={PermissionCodes.Mapping} right={Rights.Add}>
            <MappingSearchAddEdit />
          </PermissionGuard>
        ),
      },
      {
        path: ':id/edit',
        element: (
          <PermissionGuard permission={PermissionCodes.Mapping} right={Rights.Edit}>
            <MappingSearchAddEdit />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: 'experiment',
    element: <MainLayout />,
    children: [
      {
        path: '',
        element: <ExperimentFeature />,
      },
    ],
  },
  {
    path: 'MyActions',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <PermissionGuard permission={PermissionCodes.MyActions} right={Rights.Search}>
            <MyActions />
          </PermissionGuard>
        ),
      },
    ],
  },
  {
    path: '*',
    children: [
      {
        path: '',
        exact: true,
        element: <Navigate to="/dashboard" />,
      },
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'ForgotPassword',
        element: <ForgotPassword />,
      },
      {
        path: '401',
        element: <AuthorizationError />,
      },
      {
        path: '500',
        element: <ServerError />,
      },
      {
        path: '404',
        element: <NotFound />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
      {
        path: 'changepassword/:tokenId',
        element: <ClientPassword />,
      },
    ],
  },
  {
    path: 'account',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <AccountSetting />,
      },
    ],
  },
];

export default routes;
